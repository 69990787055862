import { defineStore } from "pinia";
import pinia from "@/plugins/piniaStore";
import { useDropdownUser } from "./storeDropdownData";
import { IPageLayout } from "@/helpers/api";
import { getIcon } from "@/helpers/api";
import { useDeviceStatus } from "@/stores/deviceStatus";
import { toRefs, ref, computed, reactive, watch, toRef } from "vue";
import dataTreeManager from "@/persistentStorage/wks_storage/dataTreeManager";
import { useAccountDataUser } from "@/modules/5.account/stores/accountDataStore";

const DOWNLOAD_BUTTON_INDEX = 3;
const BADGES_BUTTON_INDEX = 2;

const dropdownUser = useDropdownUser(pinia);
interface IHomepageUserResponse {
  activeFooterButtonsMe: boolean[];
  activeFooterButtonsMe_web: boolean[];
  activeFooterButtonsMentee: boolean[];
  activeFooterButtonsMentee_web: boolean[];
  activeFooterButtonsClass: boolean[];
  activeFooterButtonsClass_web: boolean[];
}

interface IFooterButton {
  label: string;
  icon: string;
  //    icon_selected: string,
  route: string;
  enabled: boolean;
  selected: boolean;
}

interface IFooterData {
  isOpen: boolean;
  firstLoad: boolean;
  footerTextLabelsUser: string[];
  footerTextLabelsClass: string[];

  activeFooterButtonsMe: boolean[];
  activeFooterButtonsMentee: boolean[];
  activeFooterButtonsClass: boolean[];
  activeFooterButtonsMe_web: boolean[];
  activeFooterButtonsMentee_web: boolean[];
  activeFooterButtonsClass_web: boolean[];
  nFooterButtons: number; //check this from de backend sreen:1100F
  footerSelectedButtonUser: number;

  //footerEnabledButtonsUser:boolean[];
  footerUserIcons: string[];
  footerUserIconsSelected: string[];

  footerSelectedButtonClass: number;
  //footerEnabledButtonsClass:boolean[];

  footerClassIcons: string[];
  footerClassIconsSelected: string[];

  footerRoutesUser: string[];
  footerRoutesClass: string[];
  isDownloadButtonEnabled: boolean;
}

interface IFooterLayout extends IPageLayout {
  labels: {
    user: string[];
    class: string[];
  };
  commands: {
    routers_class: string[];
    routers_user: string[];

    footer_button_select: number;
    user_icons: string[];
    user_icons_selected: string[];
    class_icons: string[];
    user_icons_selected_web: string[];
    class_icons_selected: string[];
    class_icons_selected_web: string[];

    // active_buttons_me: boolean[];
    // active_buttons_me_web: boolean[];
    // active_buttons_mentee: boolean[];
    // active_buttons_mentee_web: boolean[];
    // active_buttons_class: boolean[];
    // active_buttons_class_web: boolean[];
  };
}

const useFooterData = defineStore("FooterData", () => {
  const state = reactive<IFooterData>({
    isOpen: true,
    firstLoad: true,
    activeFooterButtonsMe: [false, false, false, false, false],
    activeFooterButtonsMentee: [false, false, false, false, false],
    activeFooterButtonsClass: [false, false, false, false, false],
    activeFooterButtonsMe_web: [false, false, false, false],
    activeFooterButtonsMentee_web: [false, false, false, false],
    activeFooterButtonsClass_web: [false, false, false, false, false],

    //footer data
    nFooterButtons: 0, //check this from de backend sreen:1100F
    footerSelectedButtonUser: 0,
    footerSelectedButtonClass: 0,
    footerUserIcons: [],
    footerUserIconsSelected: [],
    footerClassIcons: [],
    footerClassIconsSelected: [],
    footerTextLabelsUser: [],
    footerTextLabelsClass: [],
    footerRoutesUser: [],
    footerRoutesClass: [],
    isDownloadButtonEnabled: false,
  });

  const getFooterButtons = computed((isweb) => {
    let buttons: Array<IFooterButton>;
    if (dropdownUser.getKind == "classroom") {
      buttons = state.footerTextLabelsClass.map((l: string, i: number) => {
        const icn =
          state.footerSelectedButtonClass === i
            ? state.footerClassIconsSelected[i]
            : state.footerClassIcons[i];
        return {
          label: l,
          icon: icn,
          route: state.footerRoutesClass[i],
          enabled: state.activeFooterButtonsClass[i],
          selected: i == state.footerSelectedButtonClass,
        };
      });
    } else {
      buttons = state.footerTextLabelsUser.map((l: string, i: number) => {
        const icn =
          state.footerSelectedButtonUser === i
            ? state.footerUserIconsSelected[i]
            : state.footerUserIcons[i];
        let enabled = true;

        if (dropdownUser.getKind == "me") {
          if (
            (i === DOWNLOAD_BUTTON_INDEX && !state.isDownloadButtonEnabled) ||
            (i === BADGES_BUTTON_INDEX &&
              dropdownUser.$state.dropdownList[0].stats[0] === 0)
          ) {
            enabled = false;
          } else {
            enabled = state.activeFooterButtonsMe[i];
          }
        } else {
          enabled = state.activeFooterButtonsMentee[i];
        }

        return {
          label: l,
          icon: icn,
          route: state.footerRoutesUser[i],
          enabled: enabled,
          selected: i == state.footerSelectedButtonUser,
        };
      });
    }
    return buttons;
  });

  const getSelectedFooterButton = computed(() => {
    return dropdownUser.getKind === "classroom"
      ? state.footerSelectedButtonClass
      : state.footerSelectedButtonUser;
  });

  function isFirstLoad() {
    if (state.firstLoad) {
      state.firstLoad = false;
      return true;
    }
    return false;
  }

  function selectButtonUser(i: number) {
    state.footerSelectedButtonUser = i;
  }

  function selectButtonClass(i: number) {
    state.footerSelectedButtonClass = i;
  }

  async function setFooterData(layout: IFooterLayout) {
    console.log("+++++++++ setFooterData +++++++++");
    console.log("layout: ", layout);

    //footer icons
    //normal icons for users
    state.footerUserIcons = [];
    const localFooterUserIcons = [];
    for (let i = 0, len = layout.commands.user_icons.length; i < len; i++) {
      const icn = layout.commands.user_icons[i];
      const logo = (await getIcon("1100F", icn)) as string;
      localFooterUserIcons.push(logo);
    }

    //normal icons for classes
    state.footerClassIcons = [];
    const localFooterClassIcons = [];
    for (let i = 0, len = layout.commands.class_icons.length; i < len; i++) {
      const icn = layout.commands.class_icons[i];
      const logo = (await getIcon("1100F", icn)) as string;
      localFooterClassIcons.push(logo);
    }

    //selected icons
    //selected icons for users

    state.footerUserIconsSelected = [];
    const localFooterUserIconsSelected = [];
    const { isWeb } = toRefs(useDeviceStatus());
    const iconSet = isWeb.value
      ? layout.commands.user_icons_selected_web
      : layout.commands.user_icons_selected;
    for (let i = 0, len = iconSet.length; i < len; i++) {
      const icn = iconSet[i];
      const logo = (await getIcon("1100F", icn)) as string;
      localFooterUserIconsSelected.push(logo);
    }

    //selected icons for classes
    state.footerClassIconsSelected = [];
    const localFooterClassIconsSelected = [];
    const iconSet2 = isWeb.value
      ? layout.commands.class_icons_selected_web
      : layout.commands.class_icons_selected;
    for (let i = 0, len = iconSet2.length; i < len; i++) {
      const icn = iconSet2[i];
      const logo = (await getIcon("1100F", icn)) as string;
      localFooterClassIconsSelected.push(logo);
    }

    $patch({
      footerUserIconsSelected: localFooterUserIconsSelected,
      // activeFooterButtonsMe: layout.commands.active_buttons_me,
      // activeFooterButtonsMentee: layout.commands.active_buttons_mentee,
      // activeFooterButtonsClass: layout.commands.active_buttons_class,
      // activeFooterButtonsMe_web: layout.commands.active_buttons_me_web,
      // activeFooterButtonsMentee_web: layout.commands.active_buttons_mentee_web,
      // activeFooterButtonsClass_web: layout.commands.active_buttons_class_web,

      footerClassIcons: localFooterClassIcons,
      footerUserIcons: localFooterUserIcons,
      footerClassIconsSelected: localFooterClassIconsSelected,
      nFooterButtons: layout.labels.user.length,
      footerTextLabelsUser: layout.labels.user,
      footerTextLabelsClass: layout.labels.class,
      footerRoutesUser: layout.commands.routers_user,
      footerRoutesClass: layout.commands.routers_class,
    });
  }

  function setHomepageUserFooterData(layout: IHomepageUserResponse) {
    console.warn("setHomepageUserFooterData: ", layout);
    state.activeFooterButtonsMe = layout.activeFooterButtonsMe;
    state.activeFooterButtonsMentee = layout.activeFooterButtonsMentee;
    state.activeFooterButtonsClass = layout.activeFooterButtonsClass;
    state.activeFooterButtonsMe_web = layout.activeFooterButtonsMe_web;
    state.activeFooterButtonsMentee_web = layout.activeFooterButtonsMentee_web;
    state.activeFooterButtonsClass_web = layout.activeFooterButtonsClass_web;
  }

  function $patch(data: Partial<IFooterData>) {
    Object.assign(state, data);
  }

  /// We watch the available worksheets to toggle the download footer button
  const { uuid } = toRefs(useAccountDataUser());
  const { isNetConnected } = toRefs(useDeviceStatus());
  watch(
    [dataTreeManager.stateHash, uuid],
    async () => {
      const dataTree = await dataTreeManager.loadAndGet(uuid.value);
      const keys = dataTree.worksheets.keys();
      if ((keys && Array.from(keys).length > 0) || !isNetConnected.value) {
        // Enable download button
        // state.activeFooterButtonsMe[3] = true;
        state.isDownloadButtonEnabled = true;
        // state.activeFooterButtonsMe_web[3] = true
      } else {
        // Disable download button
        // state.activeFooterButtonsMe[3] = false;
        state.isDownloadButtonEnabled = false;
        // state.activeFooterButtonsMe_web[3] = false
      }
    },
    { immediate: true }
  );

  function setOfflineMode() {
    state.activeFooterButtonsMe = [false, false, false, true, false];
  }

  return {
    ...toRefs(state),
    getFooterButtons,
    getSelectedFooterButton,
    isFirstLoad,
    selectButtonUser,
    selectButtonClass,
    setFooterData,
    setHomepageUserFooterData,
    $patch,
    setOfflineMode,
  };
});

//******************************************************************

export {
  useFooterData,
  IFooterData,
  IFooterButton,
  IFooterLayout,
  IHomepageUserResponse,
};
