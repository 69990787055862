import { SolutionData } from "@/globals/helpers/worksheetHandler";
import {
  IAnswerWrapper,
  IOpenEndedAnswer,
  useUserAnswersData,
  IClosedEndedAnswer,
  IAssessment,
  Reviewer,
  IWorksheetAnswerStore,
} from "@/modules/a.worksheets/stores/wksUserAnswersDataStore";
import {
  IActivity,
  ProblemType,
  useWorksheetData,
} from "@/modules/a.worksheets/stores/worksheetDataStore";
import dataTreeManager from "@/persistentStorage/wks_storage/dataTreeManager";
import { writeFile } from "@/helpers/filesReadWrite";
import { toRefs } from "vue";
import { useAccountDataUser } from "@/modules/5.account/stores/accountDataStore";
import { isLocalIFile } from "@/helpers/api";
import Delta from 'quill-delta';

export function useOfflineAnswerUtils() {
  const _answerStore: ReturnType<typeof useUserAnswersData> =
    useUserAnswersData();
  const _wksStore: ReturnType<typeof useWorksheetData> = useWorksheetData();
  const { uuid } = toRefs(useAccountDataUser());

  async function setAnswer(
    taskUUID: string,
    actUUID: string,
    data: string | boolean | File[] | Delta,
    type: SolutionData
  ) {
    const activity = _wksStore.getActivity(taskUUID, actUUID);
    const problemType = activity?.problem?.problem_type;

    const problemUUID = activity?.problem?.uuid;
    if (!problemUUID) {
      console.error("Problem UUID is not set. Cannot set answer.");
      return;
    }

    const wrappedAnswer: IAnswerWrapper = {
      uuid: "unset-uuid",
      solved_at: Date.now(),
      problem: problemUUID,
      answer: {
        open_ended: null,
        close_ended: null,
      },
    };

    if (problemType === ProblemType.OPEN && typeof data !== "boolean") {
      if (typeof data === "string" || Array.isArray(data)) {
        const openEndedAnswer = await constructOpenAnswer(data, type);
        // if(!openEndedAnswer) throw new Error('Custom Error!. Open-ended answer is not constructed properly');
        wrappedAnswer.answer!.open_ended = openEndedAnswer;
      } else {
        // throw new Error(
        //   "Custom Error!. Data must be a string or an array of File objects for open-ended problems. Typeof data: " +
        //     typeof data
        // );
      }
    } else {
      const closedEndedAnswer = constructClosedAnswer(data as unknown as string);
      wrappedAnswer.answer!.close_ended = closedEndedAnswer;
    }

    _answerStore.setAnswer(wrappedAnswer);

    const wksUUID = _wksStore.uuid;
    const newSolvedRatio = calcSolvedRatio();

    dataTreeManager.updateAnswers(uuid.value, wksUUID, _answerStore.$state, {
      solvedRatio: newSolvedRatio,
      preprocess: false,
      isDataLocale: true, // Because we are operating on a local data level
    });
  }
  async function constructOpenAnswer(
    data: string | Delta | File[],
    type: SolutionData
  ): Promise<IOpenEndedAnswer> {
    let input_type = 1;
    let answer_files = [];

    switch (type) {
      case "image":
        input_type = 1;
        break;
      case "video":
        input_type = 2;
        break;
      case "audio":
        input_type = 3;
        break;
      case "pdf":
        input_type = 4;
        break;
      case "text":
        input_type = 5;
        break;
      case "document":
        input_type = 6;
        break;
    }

    if (data instanceof Delta) {
      answer_files = [
        {
          uuid: "unset-uuid",
          order: 0,
          file: null,
          textfield: null,
          textrtf: data,
        },
      ];
    }
    else if (typeof data === "string") {
      answer_files = [
        {
          uuid: "unset-uuid",
          order: 0,
          file: null,
          textfield: data,
          textrtf: null,
        },
      ];
    } else {
      for (let i = 0; i < data.length; i++) {
        const file = data[i];
        const { uri } = await writeFile("wks_files/" + file.name, file);
        answer_files.push({
          uuid: "unset-uuid",
          order: i,
          file: {
            filename: file.name,
            filetype: file.type,
            filepath: uri,
            isToUpdate: false,
            isLocal: true,
          },
          textfield: null,
          textrtf: null,
        });
      }
    }
  
    const open_ended: IOpenEndedAnswer = {
      uuid: "data.uuid",
      input_type: input_type,
      answer_files: answer_files,
      answer_assessment: [],
    };
    return open_ended;
  }
  function constructClosedAnswer(data: string): IClosedEndedAnswer {
    const closed_ended: IClosedEndedAnswer = {
      student_answer: data,
    };
    return closed_ended;
  }

  function setSelfAssessment(
    taskUUID: string,
    actUUID: string,
    assessment: string
  ) {
    const activity = _wksStore.getActivity(taskUUID, actUUID);
    const problemUUID = activity?.problem?.uuid;

    if (!problemUUID) {
      console.error("Problem UUID is not set. Cannot set answer.");
      return;
    }

    const answer = _answerStore.answers.find((a) => a.problem === problemUUID);
    if (!answer) {
      console.error("Answer not found");
      return;
    } else if (!answer.answer?.open_ended) {
      console.error("Open ended answer not found");
      return;
    }

    const newAssessment: IAssessment = {
      who_reviewed: Reviewer.SELF,
      evaluation_scale: activity.problem!.open_ended!.evaluation_scale,
      grade: assessment,
      suffix: "",
    };
    answer.answer.open_ended.answer_assessment.push(newAssessment);

    const wksUUID = _wksStore.uuid;

    console.log("_answerStore.$state", _answerStore.$state);
    dataTreeManager.updateAnswers(uuid.value, wksUUID, _answerStore.$state, {
      preprocess: false,
      isDataLocale: true,
    });
  }

  function setAnswerData(data: IWorksheetAnswerStore) {
    _answerStore.$patch(data);
  }

  function calcSolvedRatio(): number {
    let total = 0;
    let solved = 0;

    _wksStore.tasks.forEach((task) => {
      task.task.activities.forEach((activity) => {
        total++;
        if (isCompleted(activity)) solved++;
      });
    });
    return solved / total;
  }
  function isCompleted(activity: IActivity): boolean {
    const problem = activity?.problem;
    const problemUUID = problem?.uuid;
    if (!problemUUID) return false;
    return _answerStore.isAnswer(problemUUID);
  }
  async function writeFiles(files: File[]) {
    //
  }

  return {
    setAnswer,
    setSelfAssessment,
    setAnswerData,
  };
}
