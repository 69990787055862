import { createRouter, createWebHistory } from "@ionic/vue-router";
import {
  RouteRecordRaw,
  RouteMeta,
  RouteLocationNormalizedLoaded,
  Router,
  RouteLocationRaw,
  NavigationGuardNext,
} from "vue-router";
import { ref } from "vue";
import { useWorksheetCreationStore } from "@/modules/j.createWorksheets/stores/createWksStore";
import fetchPDFData from "@/modules/5.account/helpers/milageInfo";

export interface routeMetaData extends RouteMeta {
  footerIndex?: number;
  kind?: Array<"me" | "mentee" | "classroom">;
  depth?: number | typeof Number.POSITIVE_INFINITY;
}
/*
  DEPTH of Pages:
  login: 0
  homepage: 1
  other footer btns: 2
  footer btns clicks -> current+1
*/

type CustomRouteRecordSingleView = RouteRecordRaw & { meta?: routeMetaData };

type CustomRouteRecord = CustomRouteRecordSingleView;

const routes: Array<CustomRouteRecord> = [
  {
    path: "/",
    redirect: "/loading",
    meta: { footerIndex: 0 },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/HomePage.vue"),
    meta: { footerIndex: 0 },
  },
  {
    path: "/loading",
    name: "LoadingPage",
    component: () => import("@/modules/0.startApp/views/pages/LoadingPage.vue"),
  },
  {
    path: "/endApp",
    name: "EndPage",
    component: () => import("@/modules/0.startApp/views/pages/EndPage.vue"),
  },
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("@/modules/1.signin/views/pages/LoginPage.vue"),
    meta: { depth: 0 },
  },
  {
    path: "/homepage",
    name: "HomePage",
    component: () => import("@/modules/2.homepage/views/pages/HomePage.vue"),
    meta: { footerIndex: 0, depth: 1 },
  },
  {
    path: "/learn",
    name: "LearnPage",
    component: () => import("@/modules/3.learn/views/pages/LearnPage.vue"),
    meta: { footerIndex: 1, depth: 2 },
  },
  {
    path: "/excel-report-group",
    name: "ExcelReportGroup",
    component: () =>
      import("@/modules/3.learn/views/pages/ExcelReportGroup.vue"),
    meta: { footerIndex: 1, depth: 2 },
  },
  {
    path: "/user-account",
    name: "AccountPage",
    component: () => import("@/modules/5.account/views/pages/AccountPage.vue"),
    meta: { footerIndex: 4, kind: ["me", "mentee"], depth: 2 },
  },
  {
    path: "/user-account-info",
    name: "AccountPageInfo",
    component: () =>
      import("@/modules/5.account/views/pages/AccountInfoPage.vue"),
    meta: { footerIndex: 4, kind: ["me", "mentee"], depth: 3 },
  },
  {
    path: "/user-account-edit",
    name: "AccountPageEdit",
    component: () =>
      import("@/modules/5.account/views/pages/AccountEditPage.vue"),
    meta: { footerIndex: 4, kind: ["me", "mentee"], depth: 4 },
  },
  {
    path: "/user-account-country-language",
    name: "AccountPageCountryLanguage",
    component: () =>
      import("@/modules/5.account/views/pages/AccountCountryLanguagePage.vue"),
    meta: { footerIndex: 4, kind: ["me", "mentee"], depth: 3 },
  },
  {
    path: "/user-account-send-code",
    name: "AccountPageSendCode",
    component: () =>
      import("@/modules/5.account/views/pages/AccountSendCode.vue"),
    meta: {
      footerIndex: 4,
      kind: ["me", "mentee"],
      depth: Number.POSITIVE_INFINITY,
    },
  },
  {
    path: "/user-account-send-code-success",
    name: "AccountPageSendCodeSuccess",
    component: () =>
      import("@/modules/5.account/views/pages/AccountSendCodeSuccess.vue"),
    meta: {
      footerIndex: 4,
      kind: ["me", "mentee"],
      depth: Number.POSITIVE_INFINITY,
    },
  },
  {
    path: "/licenses",
    name: "Licenses",
    component: () =>
      import("@/modules/5.account/views/pages/AccountLicenses.vue"),
    meta: {
      footerIndex: 4,
      kind: ["me", "mentee"],
      depth: Number.POSITIVE_INFINITY,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () =>
      import("@/modules/5.account/views/pages/AccountTermsAndConditions.vue"),
    meta: {
      footerIndex: 4,
      kind: ["me", "mentee"],
      depth: Number.POSITIVE_INFINITY,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyAndPolicy",
    component: () =>
      import("@/modules/5.account/views/pages/AccountPrivacyAndPolicy.vue"),
    meta: {
      footerIndex: 4,
      kind: ["me", "mentee"],
      depth: Number.POSITIVE_INFINITY,
    },
  },
  {
    path: "/user-account-notifications",
    name: "AccountUserNotifications",
    component: () =>
      import("@/modules/5.account/views/pages/AccountUserNotifications.vue"),
    meta: {
      footerIndex: 4,
      kind: ["me", "mentee"],
      depth: Number.POSITIVE_INFINITY,
    },
  },
  {
    path: "/badges",
    name: "BadgesPage",
    component: () => import("@/modules/4.badges/views/pages/BadgesPage.vue"),
    meta: { footerIndex: 2, depth: 2 },
  },
  {
    path: "/offline-mode",
    name: "LearnOfflinePage",
    component: () =>
      import(
        "@/modules/k.offlineMode/2.learn/views/pages/LearnOfflinePage.vue"
      ),
    meta: { footerIndex: 3, kind: ["me", "mentee"], depth: 2 },
  },
  {
    path: "/downloads",
    name: "DownloadPage",
    component: () =>
      import("@/modules/6.downloads/views/pages/DownloadsPage.vue"),
    meta: { depth: 2 },
  },
  {
    path: "/group-students",
    name: "StudentPage",
    component: () =>
      import("@/modules/7.students/views/pages/StudentsPage.vue"),
    meta: { footerIndex: 3, kind: ["classroom"], depth: 2 },
  },

  {
    path: "/studentsInfo",
    name: "StudentInfoPage",
    component: () =>
      import("@/modules/7.students/views/pages/StudentsInfoPage.vue"),
    meta: { footerIndex: 3, kind: ["classroom"], depth: 3 },
  },
  {
    path: "/grades",
    name: "GradesPage",
    component: () => import("@/modules/8.grades/views/pages/GradesPage.vue"),
    meta: { footerIndex: 3, kind: ["classroom"], depth: 2 },
  },
  {
    path: "/info",
    name: "InfoPage",
    component: () => import("@/modules/9.info/views/pages/InfoPage.vue"),
    meta: { footerIndex: 4, kind: ["classroom"], depth: 2 },
  },
  {
    path: "/info-edit",
    name: "InfoEditPage",
    component: () => import("@/modules/9.info/views/pages/InfoEditPage.vue"),
    meta: { footerIndex: 4, kind: ["classroom"], depth: 3 },
  },

  {
    path: "/add-students-group",
    name: "AddStudentsList",
    component: () => import("@/modules/9.info/views/pages/AddStudentsList.vue"),
    meta: { footerIndex: 4, kind: ["classroom"], depth: 3 },
  },

  // JR routes

  {
    path: "/delete-my-account",
    name: "DeleteAccount",
    component: () =>
      import("@/modules/1.signin/views/modals/DeleteAccountModal.vue"),
    meta: { depth: 0 },
  },

  {
    path: "/delete-my-account-success",
    name: "DeleteAccountSuccess",
    component: () =>
      import("@/modules/1.signin/views/modals/SuccessAccountDeleted.vue"),
    meta: { depth: 0 },
  },

  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import("@/modules/1.signin/views/pages/PasswordForgot.vue"),
    meta: { depth: 0 },
  },
  {
    path: "/reset-password-info",
    name: "ResetPasswordInfo",
    component: () =>
      import("@/modules/1.signin/views/pages/PasswordForgotAinfo.vue"),
    meta: { depth: 1 },
  },
  {
    path: "/reset-password-code",
    name: "ResetPasswordCodeB",
    component: () =>
      import("@/modules/1.signin/views/pages/PasswordForgotBcode.vue"),
    meta: { depth: 2 },
  },
  {
    path: "/update-password",
    name: "ResetPasswordCodeC",
    component: () =>
      import("@/modules/1.signin/views/pages/PasswordForgotCpass.vue"),
    meta: { depth: 3 },
  },
  {
    path: "/update-password-success",
    name: "ResetPasswordCodeD",
    component: () =>
      import("@/modules/1.signin/views/pages/PasswordForgotDsuccess.vue"),
    meta: { depth: 4 },
  },
  {
    path: "/worksheet-user-page",
    name: "WorksheetUserPage",
    component: () =>
      import("@/modules/a.worksheets/views/pages/WorksheetUserPage.vue"),
    meta: { depth: 3 },
  },
  {
    path: "/wks-solve",
    redirect: "/worksheet",
  },
  {
    path: "/worksheet",
    name: "WorksheetUserPage",
    component: () =>
      import("@/modules/a.worksheets/views/pages/WorksheetUserPage.vue"),
    meta: { depth: 3 },
  },
  {
    path: "/worksheet-take-photos/:maxNumFiles?/:maxSizeMBytes?/:maxDuration?",
    name: "WorksheetTakePhotos",
    component: () =>
      import("@/modules/a.worksheets/views/pages/PhotoMediaPage.vue"),
    meta: { depth: 4 },
  },
  {
    path: "/text-editor",
    name: "WorksheetWriteText",
    component: () =>
      import("@/modules/a.worksheets/views/pages/TextEditorPage.vue"),
    meta: { depth: 4 },
  },
  {
    path: "/worksheet-take-videos",
    name: "worksheetTakeVideos",
    component: () =>
      import("@/modules/a.worksheets/views/pages/VideoMediaPage.vue"),
    meta: { depth: 4 },
  },
  {
    path: "/worksheet-take-audios",
    name: "worksheetRecordAudios",
    component: () =>
      import("@/modules/a.worksheets/views/pages/AudioMediaPage.vue"),
    meta: { depth: 4 },
  },
  {
    path: "/worksheet-upload-document",
    name: "worksheetUploadDocuments",
    component: () =>
      import("@/modules/a.worksheets/views/pages/DocumentMediaPage.vue"),
    meta: { depth: 4 },
  },
  {
    path: "/worksheet-upload-pdf",
    name: "worksheetUploadDocuments",
    component: () =>
      import("@/modules/a.worksheets/views/pages/PdfMediaPage.vue"),
    meta: { depth: 4 },
  },
  {
    //TODO: activity complete -> worksheet route instead of worksheet-solve
    path: "/worksheet-solve",
    redirect: "worksheet",
  },
  {
    path: "/teacher-class-portfolio",
    component: () =>
      import("@/modules/a.worksheets/views/pages/WorksheetUserPage.vue"),
    meta: { depth: 3 },
  },
  {
    path: "/create-account",
    component: () => import("@/modules/1.signin/views/pages/RegisterIPage.vue"),
    meta: { depth: 1 },
  },
  {
    path: "/user-country-language",
    component: () =>
      import("@/modules/1.signin/views/pages/RegisterIIPage.vue"),
    meta: { depth: 2 },
  },
  {
    path: "/nick-birth",
    component: () =>
      import("@/modules/1.signin/views/pages/RegisterIIIStuTeaPage.vue"),
    meta: { depth: 3 },
  },
  {
    path: "/underage-parent-email",
    component: () =>
      import("@/modules/1.signin/views/pages/RegisterUnderAgeParentPage.vue"),
    meta: { depth: 4 },
  },
  {
    path: "/choose-role",
    component: () =>
      import("@/modules/1.signin/views/pages/RegisterChooseRolePage.vue"),
    meta: { depth: 5 },
  },
  {
    path: "/teacher-download-mooc",
    component: () =>
      import("@/modules/1.signin/views/pages/RegisterTeacherMooc.vue"),
    meta: { depth: 6 },
  },
  {
    path: "/create-account-success",
    component: () =>
      import("@/modules/1.signin/views/pages/RegisterSuccessPage.vue"),
    meta: { depth: 7 },
  },
  {
    path: "/activation-code",
    component: () =>
      import("@/modules/1.signin/views/pages/ActivationCodePage.vue"),
    meta: { depth: 8 },
  },
  {
    path: "/activation-info",
    component: () =>
      import("@/modules/1.signin/views/pages/ActivationInfo.vue"),
  },
  {
    path: "/activation-success",
    component: () =>
      import("@/modules/1.signin/views/pages/ActivationSuccessPage.vue"),
    meta: { depth: 9 },
  },

  //-----
  //------jirodrig -------

  {
    path: "/go-worksheet/:uuid",
    name: "GoWorksheet",
    component: () => import("@/modules/3.learn/helpers/goWorksheet.vue"),
  },

  {
    path: "/geolocation",
    name: "GeolocationPage",
    component: () =>
      import("@/modules/d.notifications/views/pages/NotificationsPage.vue"),
  },
  {
    path: "/chat",
    name: "ChatPage",
    component: () =>
      import("@/modules/d.notifications/views/pages/NotificationsPage.vue"),
  },
  {
    path: "/notifications",
    name: "NotificartionsPage",
    component: () =>
      import("@/modules/d.notifications/views/pages/NotificationsPage.vue"),
  },
  {
    path: "/ranks",
    name: "RankingsPage",
    component: () =>
      import("@/modules/e.rankings/views/pages/RankingsPage.vue"),
    meta: { footerIndex: 1 },
  },
  {
    path: "/courses",
    name: "CousesPage",
    component: () =>
      import("@/modules/d.notifications/views/pages/NotificationsPage.vue"),
    meta: { depth: 3 },
  },
  {
    path: "/classes",
    name: "ClassesPage",
    component: () =>
      import("@/modules/d.notifications/views/pages/NotificationsPage.vue"),
    meta: { depth: 3 },
  },
  {
    path: "/user-classes",
    name: "UserClasses",
    component: () => import("@/modules/f.courses/views/pages/ClassesPage.vue"),
    meta: { footerIndex: 4, kind: ["me", "mentee"], depth: 3 },
  },
  {
    path: "/archive",
    name: "ArchivePage",
    component: () =>
      import("@/modules/d.notifications/views/pages/NotificationsPage.vue"),
  },
  {
    path: "/search",
    name: "SearchPage",
    component: () =>
      import("@/modules/d.notifications/views/pages/NotificationsPage.vue"),
  },
  {
    path: "/statistics",
    name: "SearchPage",
    component: () =>
      import("@/modules/d.notifications/views/pages/NotificationsPage.vue"),
  },
  {
    path: "/user-courses",
    component: () => import("@/modules/f.courses/views/pages/CoursesPage.vue"),
    meta: { footerIndex: 4, kind: ["me", "mentee"], meta: { depth: 3 } },
  },
  {
    path: "/user-mentees",
    component: () => import("@/modules/g.mentees/views/MenteesPage.vue"),
    meta: { footerIndex: 4, kind: ["me", "mentee"], depth: 4 },
  },
  {
    path: "/create-new-class",
    component: () =>
      import("@/modules/i.createClassesCourses/views/pages/AddClassPage.vue"),
    meta: { footerIndex: 4, kind: ["me", "mentee"], depth: 4 },
  },

  {
    path: "/external-html",
    name: "ExternalHTMLPage",
    props: (route) => ({ src: route.query.src }),
    component: () => import("@/globals/pages/ExternalHTMLPage.vue"),
    meta: {},
  },
  {
    path: "/about",
    component: () =>
      import("@/modules/5.account/views/pages/AccountAboutPage.vue"),
    meta: { footerIndex: 4, kind: [], depth: 3 },
  },
  {
    path: "/create-worksheet",
    component: () =>
      import(
        "@/modules/j.createWorksheets/views/pages/CreateUpdateWorksheet.vue"
      ),
    beforeEnter: (
      to: RouteLocationNormalizedLoaded,
      from: RouteLocationNormalizedLoaded,
      next: NavigationGuardNext
    ) => {
      // const createWksStore = useWorksheetCreationStore(pinia);
      const createWksStore = useWorksheetCreationStore();
      createWksStore.isNew = true;
      next();
    },
  },
  {
    path: "/create-update-problems",
    component: () =>
      import(
        "@/modules/j.createWorksheets/views/pages/CreateUpdateProblems.vue"
      ),
  },
  {
    path: "/create-update-worksheet",
    component: () =>
      import(
        "@/modules/j.createWorksheets/views/pages/CreateUpdateWorksheet.vue"
      ),
  },
  {
    path: "/download-wks",
    component: () =>
      import(
        "@/modules/k.offlineMode/0.learnDownloadWks/views/pages/DownloadWksPage.vue"
      ),
  },
  {
    path: "/wks-solve-offline",
    name: "WksSolveOffline",
    component: () =>
      import(
        "@/modules/k.offlineMode/1.worksheets/views/pages/WorksheetUserPageOffline.vue"
      ),
  },
  {
    path: "/upload-wks",
    component: () =>
      import(
        "@/modules/k.offlineMode/3.learnUpload/views/pages/LearnUploadPage.vue"
      ),
  },
  {
    path: "/worksheet-take-photos-offline",
    name: "WorksheetTakePhotosOffline",
    component: () =>
      import(
        "@/modules/k.offlineMode/1.worksheets/views/pages/PhotoMediaPageOffline.vue"
      ),
    meta: { depth: 4 },
  },
  {
    path: "/worksheet-take-videos-offline",
    name: "worksheetTakeVideosOffline",
    component: () =>
      import(
        "@/modules/k.offlineMode/1.worksheets/views/pages/VideoMediaPageOffline.vue"
      ),
    meta: { depth: 4 },
  },
  {
    path: "/worksheet-take-audios-offline",
    name: "worksheetRecordAudiosOffline",
    component: () =>
      import(
        "@/modules/k.offlineMode/1.worksheets/views/pages/AudioMediaPageOffline.vue"
      ),
    meta: { depth: 4 },
  },
  {
    path: "/worksheet-upload-document-offline",
    name: "worksheetUploadDocumentsOffline",
    component: () =>
      import(
        "@/modules/k.offlineMode/1.worksheets/views/pages/DocumentMediaPageOffline.vue"
      ),
    meta: { depth: 4 },
  },
  {
    path: "/video-create",
    component: () =>
      import("@/modules/m.videorecorder/views/pages/RecordVideo.vue"),
  },
  {
    path: "/worksheet-upload-pdf-offline",
    name: "worksheetUploadDocumentsOffline",
    component: () =>
      import(
        "@/modules/k.offlineMode/1.worksheets/views/pages/PdfMediaPageOffline.vue"
      ),
    meta: { depth: 4 },
  },
  //COURSE_MANAGEMENT
  {
    path: "/course-management-info",
    component: () =>
      import(
        "@/modules/r.courseManagement/views/pages/CourseManagementInfo.vue"
      ),
  },

  {
    path: "/course-management-new",
    component: () =>
      import(
        "@/modules/r.courseManagement/views/pages/CourseManagementNewCourse.vue"
      ),
  },

  {
    path: "/course-management-edit",
    component: () =>
      import(
        "@/modules/r.courseManagement/views/pages/CourseManagementEdit.vue"
      ),
  },

  {
    path: "/course-management-curricula",
    component: () =>
      import(
        "@/modules/r.courseManagement/views/pages/CurriculaManagement.vue"
      ),
  },
  {
    path: "/temp",
    component: () => import("@/modules/t.temp/TempPage.vue"),
  },
  {
    path: "/translations",
    component: () =>
      import("@/modules/z.translations/views/pages/HandleTranslations.vue"),
    meta: { footerIndex: 4, kind: [], depth: 3 },
  },
  {
    path: "/milage-info/:id",
    beforeEnter: (to, from, next) => {
      // Trigger your method
      console.log("AQUI_CHEGADOS", typeof to.params.id);
      const id = to.params.id as string;
      fetchPDFData(id);
      // Replace the current route with another one
      next({ path: "/user-account", replace: true });
    },
    component: () => import("@/modules/5.account/views/pages/MilageInfo.vue"),
    props: (route) => ({ id: Number(route.params.id) }),
    meta: { footerIndex: 4, kind: [], depth: 3 },
  },
];

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

class CustomRouter implements Router {
  routeHistory = ref<RouteLocationNormalizedLoaded[]>([]);

  _router: Router;
  public forceNextRoute = false;

  constructor(router: Router) {
    this._router = router;

    this.addRoute = this._router.addRoute.bind(this._router);

    this.afterEach = this._router.afterEach.bind(this._router);
    this.beforeEach = this._router.beforeEach.bind(this._router);
    this.beforeResolve = this._router.beforeResolve.bind(this._router);
    this.clearRoutes = this.clearRoutes.bind(router);

    this.forward = this._router.forward.bind(this._router);
    this.getRoutes = this._router.getRoutes.bind(this._router);
    this.go = this._router.go.bind(this._router);
    this.hasRoute = this._router.hasRoute.bind(this._router);
    this.install = this._router.install.bind(this._router);
    this.isReady = this._router.isReady.bind(this._router);

    this.onError = this._router.onError.bind(this._router);

    //this.push = this._router.push.bind(this._router)
    this.removeRoute = this._router.removeRoute.bind(this._router);
    this.replace = this._router.replace.bind(this._router);
    this.resolve = this._router.resolve.bind(this._router);
  }

  addRoute = router.addRoute.bind(router);
  afterEach = router.afterEach;
  beforeEach = router.beforeEach;
  beforeResolve = router.beforeResolve;

  currentRoute = router.currentRoute;
  forward = router.forward;
  getRoutes = router.getRoutes;
  go = router.go;
  hasRoute = router.hasRoute;
  install = router.install;
  isReady = router.isReady;
  listening = router.listening;
  onError = router.onError;
  options = router.options;
  push = (to: RouteLocationRaw) => {
    this.forceNextRoute = true;
    return this._router.push(to);
  };
  removeRoute = router.removeRoute;
  replace = (to: RouteLocationRaw) => {
    this.forceNextRoute = true;
    return router.push(to);
  };
  resolve = router.resolve;
  clearRoutes = () => {
    const allRoutes = this.getRoutes();
    allRoutes.forEach((route) => {
      if (route.name) {
        this.removeRoute(route.name);
      }
    });
  };

  back = () => {
    // get the current route
    const currentRoute = router.currentRoute.value;
    const meta = currentRoute.meta as routeMetaData;
    // get the current depth
    const currentDepth = meta.depth;

    let previousRoute: null | RouteLocationNormalizedLoaded = null;
    for (let i = this.routeHistory.value.length - 2; i >= 0; i--) {
      const meta = this.routeHistory.value[i].meta as routeMetaData;

      if (currentDepth === undefined && meta.depth !== undefined) {
        previousRoute = this.routeHistory.value[i];
        break;
      }

      if (currentDepth && meta && meta.depth && meta.depth < currentDepth) {
        previousRoute = this.routeHistory.value[i];
        break;
      }
    }

    if (previousRoute) {
      this.forceNextRoute = true;
      this.includeRouteInTree(previousRoute);
      router.push(previousRoute.fullPath);
    } else {
      //console.error("No hierarchically higher route found in the history.");
      this._router.back(); // fallback logic
    }
  };
  private includeRouteInTree(newRoute: RouteLocationNormalizedLoaded) {
    const newRouteDepth = (newRoute.meta as routeMetaData).depth;
    console.log("newRouteDepth", newRouteDepth);
    if (typeof newRouteDepth === "number") {
      console.log("routeHistory before filtering", this.routeHistory.value);
      this.routeHistory.value = this.routeHistory.value.filter(
        (r) =>
          r.meta.depth !== undefined && (r.meta.depth as number) < newRouteDepth
      );
      console.log("routeHistory after filtering", this.routeHistory.value);
    }

    this.routeHistory.value.push(newRoute);
  }
}

// const customRouter = new CustomRouter(router)
// console.log("custom router", customRouter)

router.beforeEach((to, from) => {
  // ...
  // explicitly return false to cancel the navigation
  console.log("ROUTER FROM", from);
  console.log("ROUTER  TO", to);
  if (to.path === "/") {
    console.log("FOUND");
  }
});

export default router;

/*
  addRouter = router.addRoute;
  afterEach = router.afterEach
  beforeEach = router.beforeEach
  beforeResolve = router.beforeResolve
  currentRoute = router.currentRoute
  forward = router.forward
  getRoutes = router.getRoutes
  go = router.go
  hasRoute = router.hasRoute
  install = router.install
  isReady = router.isReady
  listening = router.listening
  onError = router.onError
  options = router.options;
  push = router.push.bind(router)
  removeRoute = router.removeRoute;
  replace = router.replace
  resolve = router.resolve

*/
