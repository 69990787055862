<template>

  <div v-if="keyboardId === 9 && layout" class="editor-container" :class="{ 'no-max-height': noMaxHeight }">
      <QuillEditor id="quill-editor" @text-change="onTextChange" v-model:content="content" theme="snow"
          content-type="delta" :placeholder="layout.helps.editor" ref="quill"
          :options="editorOptionsP" />
  </div>

  <div v-else-if="keyboardId === 4  && layout " class="editor-container" :class="{ 'no-max-height': noMaxHeight }">
      <QuillEditor id="quill-editor" @text-change="onTextChange" v-model:content="content" theme="snow"
          content-type="delta" :placeholder="layout.helps.editor" ref="quill"
          :options="editorOptionsA" />
  </div>

  <div v-else-if="keyboardId === 3  && layout " class="editor-container" :class="{ 'no-max-height': noMaxHeight }">
      <QuillEditor id="quill-editor" @text-change="onTextChange" v-model:content="content" theme="snow"
          content-type="delta" :placeholder="layout.helps.editor" ref="quill"
          :options="editorOptionsA" />
  </div>

  <div v-else-if="keyboardId === 2  && layout" class="editor-container" :class="{ 'no-max-height': noMaxHeight }">
      <QuillEditor id="quill-editor" @text-change="onTextChange" v-model:content="content" theme="snow"
          content-type="delta" :placeholder="layout.helps.editor" ref="quill"
          :options="editorOptionsA" />
  </div>


</template>


<script lang="ts" setup>
import { IonPage, IonContent } from "@ionic/vue";

import "/src/types/jquery.js";
// KaTeX dependency
import katex from "katex";
window.katex = katex;

import { QuillEditor } from "@vueup/vue-quill";
import "quill/dist/quill.snow.css";
import { Quill } from "@vueup/vue-quill";

import "@edtr-io/mathquill/build/mathquill.js";
import "@edtr-io/mathquill/build/mathquill.css";
import "@/assets/mathquill4quill.css";
// mathquill4quill include
import mathquill4quill from "@/assets/mathquill4quill.js";

import { handleError, nextTick, onMounted, PropType, ref, watch } from "vue";
import Delta from 'quill-delta';
import { getComputedPageLayout, getIcon } from "@/helpers/api";
import requestService from "@/services/requests";
import { Keyboard } from "swiper";

const props = defineProps({
  initialContent: {
      type: Object as PropType<Delta | null>,
      default: () => new Delta(),
      required: false,
  },
  noMaxHeight: {
      type: Boolean,
      default: false
  },
  keyboardId: {
      type: Number,
      default: 0
  },
});


interface EditorCommands {
  editorOptions: any;
  operators: any[][][];
}

interface EditorItem {
  screen_id: string;
  icons: string | null;
  iconsdark: string | null;
  commands: EditorCommands;
  update: string;
  image_layout: string;
}

interface _1020 {
  helps: {
    editor: string,
  }
}


const emit = defineEmits<{
  (e: 'update-content', delta: Delta): void;
}>();

const layout = getComputedPageLayout<_1020>("1020");

const editorOptionsP = ref({
  theme: "snow",
    modules: {
        toolbar: [
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video",],
            ["formula"],
        ],
        formula: true,
    },
});

const editorOptionsA = ref({
  theme: "snow",
  modules: {
      toolbar: [
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
          ["formula"],
      ],
      formula: true,
  },
});


const operatorsP = [
  [
      ["a^2", "^2"], //igual
      ["a^x", "^{}"], //igual
      ["a_x", "_{}"], //igual
      ["\\sqrt{x}", "\\sqrt"], //igual
      ["\\frac{a}{x}", "\\frac"], //igual
      ["\\operatorname{sen()}", "\\operatorname{sen()}"],  //igual
      ["\\cos()", "\\cos()"],  //igual
      ["\\operatorname{tg()}", "\\operatorname{tg()}"],  //igual
      ["\\operatorname{sen}^{-1}", "\\operatorname{sen^{-1}()}"],  //igual
      ["\\cos^{-1}", "\\cos^{-1}()"],  //igual
      ["\\operatorname{tg}^{-1}", "\\operatorname{tg^{-1}()}"],  //igual
      ["\\overrightarrow{XA}", "\\overrightarrow{XA}"], //igual
      ["x^{\\circ}", "{}^{\\circ}"],  //igual
      ["\\overline{x}", "\\overline{}"],  //igual
      ["\\dot{x}", "\\dot{}"], //igual
      ["\\hat{x}", "\\hat{}"],  //igual
  ],


  [
      ["+", "+"],
      ["-", "-"],
      ["\\pm", "\\pm"],
      ["\\times", "\\times"],
      ["\\div", "\\div"],
      ["=", "="],
      ["\\simeq", "\\simeq"],
      ["<", "<"],
      [">", ">"],
      ["\\le", "\\le"],
      ["\\ge", "\\ge"],
      ["\\Longleftrightarrow", "\\Longleftrightarrow"],
      ["\\pi", "\\pi"],

      ["\\cap", "\\cap"],
      ["\\cup", "\\cup"],
      ["\\in", "\\in"],
      ["\\wedge", "\\wedge"],
      ["\\vee", "\\vee"],
      ["\\varnothing", "\\varnothing"],
      ["\\infty", "\\infty"],
      ["\\%", "\\%"],
  ],

  [
      ["\\text{ℕ}", "\\text{ℕ}"],
      ["\\text{ℤ}", "\\text{ℤ}"],
      ["\\text{ℚ}", "\\text{ℚ}"],
      ["\\text{ℝ}", "\\text{ℝ}"],
  ],


  [
      ["(x)", "({})"],
      ["[x]", "[{{}}]"],
      ["\\left\\{x\\right\\}", "\\left\\{{}\\right\\}"],
      ["[x[", "[{}["],
      ["]x]", "]{}]"],
      ["]x[", "]{}["]
  ],
];

const operators3 = [
  [
      ["a^2", "^2"], //igual
      ["a^x", "^{}"], //igual
      ["a_x", "_{}"], //igual
      ["\\sqrt{x}", "\\sqrt"], //igual
      ["\\frac{a}{x}", "\\frac"], //igual
      ["\\operatorname{sen()}", "\\operatorname{sen()}"],  //igual
      ["\\cos()", "\\cos()"],  //igual
      ["\\operatorname{tg()}", "\\operatorname{tg()}"],  //igual
      ["\\operatorname{sen}^{-1}", "\\operatorname{sen^{-1}()}"],  //igual
      ["\\cos^{-1}", "\\cos^{-1}()"],  //igual
      ["\\operatorname{tg}^{-1}", "\\operatorname{tg^{-1}()}"],  //igual
      ["\\overrightarrow{XA}", "\\overrightarrow{XA}"], //igual
      ["x^{\\circ}", "{}^{\\circ}"],  //igual
      ["\\overline{x}", "\\overline{}"],  //igual
      ["\\dot{x}", "\\dot{}"], //igual
      ["\\hat{x}", "\\hat{}"],  //igual
  ],


  [
      ["+", "+"],
      ["-", "-"],
      ["\\pm", "\\pm"],
      ["\\times", "\\times"],
      ["\\div", "\\div"],
      ["=", "="],
      ["\\simeq", "\\simeq"],
      ["<", "<"],
      [">", ">"],
      ["\\le", "\\le"],
      ["\\ge", "\\ge"],
      ["\\Longleftrightarrow", "\\Longleftrightarrow"],
      ["\\pi", "\\pi"],

      ["\\cap", "\\cap"],
      ["\\cup", "\\cup"],
      ["\\in", "\\in"],
      ["\\wedge", "\\wedge"],
      ["\\vee", "\\vee"],
      ["\\varnothing", "\\varnothing"],
      ["\\infty", "\\infty"],
      ["\\%", "\\%"],
  ],

  [
      ["\\text{ℕ}", "\\text{ℕ}"],
      ["\\text{ℤ}", "\\text{ℤ}"],
      ["\\text{ℚ}", "\\text{ℚ}"],
      ["\\text{ℝ}", "\\text{ℝ}"],
  ],


  [
      ["(x)", "({})"],
      ["[x]", "[{{}}]"],
      ["\\left\\{x\\right\\}", "\\left\\{{}\\right\\}"],
      ["[x[", "[{}["],
      ["]x]", "]{}]"],
      ["]x[", "]{}["]
  ],
];

const operators2 = [
  [
      ["\\frac{a}{x}", "\\frac"], //igual
      ["\\overline{x}", "\\overline{}"],  //igual
  ],


  [
      ["+", "+"],
      ["-", "-"],
      ["\\times", "\\times"],
      ["\\div", "\\div"],
      ["=", "="],
  ],

  [
      ["(x)", "({})"],
      ["[x]", "[{{}}]"],
  ],
];




const quill = ref();
const content = ref<Delta>(new Delta());
watch(
  () => props.initialContent,
  (newVal) => {
      if (newVal) {
          content.value = new Delta(newVal);
      }
  },
  { immediate: true }
);

const onTextChange = () => {
  const cl = document.getElementsByClassName('ql-formula');

  if (quill.value) {
      const delta = quill.value.getContents();
      content.value = delta as Delta;
      console.log("DELTAA", delta);
      emit('update-content', delta as Delta);
  }


  for (const i of cl) {
      i.setAttribute("title", "Editar");

      const formulaContent = i.getAttribute("data-value") || "";
      i.setAttribute("data-value", formulaContent);


      i.removeEventListener("click", handleClick);
      i.addEventListener("click", handleClick);
  }
};


function fixTooltipPosition() {
  const tooltip = document.querySelector('.ql-tooltip');
  if (tooltip) {
      const tooltipEl = tooltip as HTMLElement;

      tooltipEl.style.removeProperty('left');
      tooltipEl.style.removeProperty('top');

      tooltipEl.classList.add('fixed-tooltip');
  }
}


let currentFormulaIndex: number | null = null;
let originalFormula = '';

function handleClick(e: any) {
  const target = (e.target as HTMLElement).closest('.ql-formula');
  if (!target || !quill.value) return;

  originalFormula = target.getAttribute("data-value") || "";
  const quillEditor = quill.value.getQuill();
  const blot = Quill.find(target);
  if (!blot) return;

  currentFormulaIndex = quillEditor.getIndex(blot);

  quillEditor.setSelection(currentFormulaIndex, 1, 'silent');

  const tooltip = quillEditor.theme.tooltip;
  tooltip.edit('formula', originalFormula);

  setTimeout(() => {
      fixTooltipPosition();

      const mathquillInput = tooltip.root.querySelector('.mq-editable-field');
      if (mathquillInput) {
          const MQ = (window as any).MathQuill.getInterface(2);
          const mathField = MQ(mathquillInput);
          mathField.latex(originalFormula);
          mathField.focus();
      }
      interceptSave(tooltip, quillEditor);

  }, 0);
}

function interceptSave(tooltip: any, quillEditor: any) {
  const oldSave = tooltip.save.bind(tooltip);
  tooltip.save = () => {
      const mathquillInput = tooltip.root.querySelector('.mq-editable-field');
      const MQ = (window as any).MathQuill.getInterface(2);
      const mathField = MQ(mathquillInput);
      const newFormula = mathField.latex();

      if (newFormula === originalFormula) {
          tooltip.cancel();
      } else {

          oldSave();
          removeOldFormula(quillEditor, currentFormulaIndex as number);
      }
      tooltip.save = oldSave;
  };
}

function removeOldFormula(quillEditor: any, index: number) {
  quillEditor.deleteText(index, 1, 'user');
}

let mathQuillAlreadyEnabled = false;
const x_icon = ref<string>()
let parsedOptions: any



onMounted(async () => {
  
  await nextTick();
  x_icon.value = await getIcon("1010", "close");

  if (quill.value) {
      try {
          quill.value.container = quill.value.getEditor();

          const quillEditor = quill.value.getQuill();
          const tooltip = quillEditor.theme.tooltip;
          if (x_icon.value) {
              addCloseButtonToTooltip(tooltip, x_icon.value);
          }

          const toolbar = quillEditor.getModule('toolbar');
          if (toolbar) {
              toolbar.container.addEventListener('click', (event: { target: HTMLElement; }) => {
                  const target = event.target as HTMLElement;
                  if (target && target.classList.contains('ql-formula')) {
                      setTimeout(() => {
                          fixTooltipPosition();
                      }, 0);
                  }
              });
          }

          onTextChange();
      } catch (error) {
          console.error("Erro ao inicializar o editor:", error);
      }
  } else {
      console.error("QuillEditor ref 'quill' está nulo após nextTick.");
  }

  if (!mathQuillAlreadyEnabled  && quill.value) {
      const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
      let operators
      if (props.keyboardId === 9 || props.keyboardId === 4) {
        operators = operatorsP
      }
      else if (props.keyboardId === 3) {
        operators = operators3
      }
      else if (props.keyboardId === 2) {
        operators = operators2
      }
      enableMathQuillFormulaAuthoring(quill.value, { operators });
      mathQuillAlreadyEnabled = true;
  }
});

function addCloseButtonToTooltip(tooltip: any, iconUrl: string) {
  const existingBtn = tooltip.root.querySelector('.close-tooltip-btn');
  if (existingBtn) return;

  const closeButton = document.createElement('button');
  closeButton.classList.add('close-tooltip-btn');

  const iconImg = document.createElement('img');
  iconImg.src = iconUrl;
  iconImg.classList.add('close-tooltip-icon');

  closeButton.appendChild(iconImg);

  // Ao clicar no botão, “cancela” o tooltip (fecha).
  closeButton.addEventListener('click', () => {
      tooltip.cancel();
  });

  tooltip.root.appendChild(closeButton);
}



</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #121621;
  text-align: center;
}

footer a {
  color: #fff;
  font-family: sans-serif;
}

footer p {
  margin: 0.5em;
}

footer img {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
  display: none;
}

main {
  width: 100%;
  height: 200px;
}

@media (min-width: 500px) {
  main {
      max-width: 400px;
      margin: 50px auto;
  }

  footer p {
      display: none;
  }

  footer img {
      display: block;
  }
}

label {
  padding-top: 0.5em;
  display: block;
}

.editor-container {
  top: 100px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  max-height: 35%;
}

.editor-container.no-max-height {
max-height: none !important;
}

.quill-container {
  font-size: 22px;
}



.ql-editor {
  font-size: 22px;
}

.fixed-tooltip {
  position: fixed !important;
  left: 50% !important;
  top: 20% !important;
  width: 90% !important;
  transform: translateX(-50%) !important;
  z-index: 1000 !important;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
</style>
