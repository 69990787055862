<template>
  <div v-if="isWeb" class="flexbox relative" :style="getInputCSS">
    <input type="file" hidden ref="inputElement" accept="application/pdf, image/jpeg, image/png"
      @change="processDocument" />

    <div class="wrapper">
      <div class="input-box-wrapper">
        <div class="open-input-box">
          <textarea v-model="text" :placeholder="placeholder" ref="inputRef" :rows="textAreaRows"></textarea>
        </div>
      </div>
    </div>

    <div v-if="filePreview" class="file-preview">
      <div class="action-buttons">
        <img :src="deleteSvg" class="action-button remove-button" @click="removeFile" alt="Remover Arquivo" />

        <img v-if="isImage(filePreview.type)" :src="expand" class="action-button expand-button" @click="openMediaModal"
          alt="Expandir Imagem" />


      </div>

      <div v-if="isImage(filePreview.type)" class="image-preview">
        <img :src="filePreview.url" alt="Pré-visualização de Imagem" />
      </div>
      <div v-else-if="isPDF(filePreview.type)" class="pdf-preview">
        <embed :src="filePreview.url" type="application/pdf" width="100%" height="200px" />
      </div>
    </div>

    <div class="icons">
      <WksOpenInputBtn :src="uploadSvg" route-name="file" @click="fsSelectFile" />
      <Transition name="send">
        <div v-if="canSend" class="send-button" @click="sendMedia" v-html="sendSvg"></div>
      </Transition>
    </div>
  </div>

  <div v-else class="flexbox relative" :style="getInputCSS">

    <div v-if="filePreview" class="file-preview">
      <div class="action-buttons">
        <img :src="deleteSvg" class="action-button remove-button" @click="removeFile" alt="Remover Arquivo" />

        <img v-if="isImage(filePreview.type)" :src="expand" class="action-button expand-button" @click="openMediaModal"
          alt="Expandir Imagem" />


      </div>


      <div v-if="isImage(filePreview.type)" class="image-preview">
        <img :src="filePreview.url" alt="Pré-visualização de Imagem" />
      </div>
      <div v-else-if="isPDF(filePreview.type)" class="pdf-preview">
        <PdfViewer :source="filePreview!.url" :page="pageNumbers[filePreview!.url] || 1"
          @page-changed="newPage => setPageNr(filePreview!.url, newPage)" />
      </div>
    </div>


    <div class="wrapper">
      <div class="input-box-wrapper">
        <div class="open-input-box">
          <textarea v-model="text" :placeholder="placeholder" ref="inputRef" :rows="textAreaRows"></textarea>
        </div>
      </div>
      <div class="icons">
        <input class="input-button" type="file" hidden ref="inputElement"
          accept="application/pdf, image/jpeg, image/png" @change="processDocument" />
        <WksOpenInputBtn :src="uploadSvg" route-name="file" @click="fsSelectFile" />
        <Transition name="send">
          <div v-if="canSend" class="send-button" @click="sendMedia" v-html="sendSvg"></div>
        </Transition>
      </div>

    </div>



  </div>
</template>


<script setup lang="ts">
import {
  computed,
  ref,
  onMounted,
  watch,
  shallowRef,
  PropType,
  toRefs,
  onBeforeUnmount,
} from "vue";
import router from "@/router";
import { getIcon } from "@/helpers/api";
import CssUtils from "@/helpers/cssUtils";
import { IFont, getFont } from "@/helpers/fonts";
import { nanoid } from "nanoid";
import { useOpenInputMediaStore } from "@/modules/a.worksheets/stores/openInputMediaStore";
import { useDeviceStatus } from "@/stores/deviceStatus";
import WksOpenInputPopUpBtn from "@/modules/a.worksheets/views/components/footer/WksOpenInputPopUpBtn.vue";
import WksOpenInputBtn from "@/modules/a.worksheets/views/components/footer/WksOpenInputBtn.vue";
import { TInputButton } from "@/modules/a.worksheets/helpers/wksOpenInput";
import { RouteParamsRaw } from "vue-router";
import { SolutionData, useWorksheetHandler } from '@/globals/helpers/worksheetHandler';
import { modalController } from "@ionic/vue";
import WksMediaModal from "@/globals/components/WksMediaModal.vue";
import PdfViewer from "@/globals/components/PdfViewer/PdfViewer.vue";

const { isWeb } = toRefs(useDeviceStatus());

const isOptionsBoxOpen = ref(false);
const props = defineProps({
  mediaButton: {
    type: Array as PropType<TInputButton[]>,
    default: () => [],
  },
  placeholder: { type: String, default: "" },
  textBtn: {
    type: Object as PropType<{ src: string; route: string }>,
    default: () => ({}),
  },
  isText: { type: Boolean, default: true },
  params: { type: Object as PropType<RouteParamsRaw>, default: () => ({}) },
});

interface MediaPayload {
  text: string;
  files: File[];
}

const emit = defineEmits<{
  (e: 'set-media', payload: MediaPayload, type: SolutionData): void,
}>();

const modifiedButtons = ref<
  Array<{
    route: string;
    src: string;
    svg: string;
    name: string;
    routeName: string;
  }>
>();
const inputRef = shallowRef<HTMLTextAreaElement | null>(null);

const nore12 = ref<IFont>();
const nore16 = ref<IFont>();



const plusSvg = ref<string | undefined>();
const uploadSvg = ref<string | undefined>();
const sendSvg = ref<string | undefined>();
const deleteSvg = ref<string | undefined>();
const expand = ref<string>();
const leftArrow = ref<string>();
const rightArrow = ref<string>();

onMounted(async () => {
  leftArrow.value = await getIcon("1602", "previous_page");
  rightArrow.value = await getIcon("1602", "next_page");
  deleteSvg.value = await getIcon("2105", "bin");
  plusSvg.value = await getIcon("1602", "plus");
  expand.value = await getIcon("1602", "expand");
  uploadSvg.value = await getIcon("2101", "upload");
  nore12.value = await getFont("nore12");
  nore16.value = await getFont("nore16");
  const sendBlobUrl = await getIcon("1610A", "send");
  if (sendBlobUrl) {
    sendSvg.value = await fetch(sendBlobUrl).then((r) => r.text());
  }
});

function route(path: string) {
  isOptionsBoxOpen.value = false;
  router.replace(path);
}

const pageNumbers = ref<Record<string, number>>({});

const setPageNr = (key: string, newPage: number) => {
  pageNumbers.value[key] = newPage;
};

const getInputCSS = computed(() => {
  const backgroundColor = isWeb.value
    ? "var(--worksheet-difficulty-bg)"
    : "#EFEFEF";
  return {
    "--placeholder-font": isWeb.value
      ? nore16.value?.["font-family"]
      : nore12.value?.["font-family"],
    "--placeholder-font-size": isWeb.value
      ? nore16.value?.["font-size"]
      : nore12.value?.["font-size"],
    "--placeholder-font-weight": isWeb.value
      ? nore16.value?.["font-weight"]
      : nore12.value?.["font-weight"],
    "--placeholder-transform": isWeb.value
      ? nore16.value?.["text-transform"]
      : nore12.value?.["text-transform"],
    "--input-background": backgroundColor,
  };
});

const setInputText = (e: InputEvent) =>
  (text.value = e.target ? (e.target as HTMLTextAreaElement).value : "");

const text = ref("");


const selectedFile = ref<File | null>(null);
const filePreview = ref<{ url: string; type: string } | null>(null);

function isImage(type: string): boolean {
  return ['image/png', 'image/jpeg', 'image/jpg'].includes(type);
}

function isPDF(type: string): boolean {
  return type === 'application/pdf';
}

async function sendMedia() {
  if (selectedFile.value || text.value.trim() !== "") {
    const payload: MediaPayload = {
      text: text.value.trim(),
      files: selectedFile.value ? [selectedFile.value] : []
    };

    // Determina o tipo com base nos arquivos
    let type: SolutionData;
    if (selectedFile.value) {
      type = isPDF(selectedFile.value.type) ? "pdf" : "image";
    } else {
      type = "text";
    }

    emit("set-media", payload, type);

    if (inputRef.value) inputRef.value.value = "";
    text.value = "";
    removeFile();
  }
}


async function processDocument() {
  if (inputElement.value && inputElement.value.files) {
    const file = inputElement.value.files[0];
    console.log("file.type", file.type);
    inputElement.value.value = '';

    if (isImage(file.type) || isPDF(file.type)) {
      selectedFile.value = file;
      const url = URL.createObjectURL(file);
      filePreview.value = { url, type: file.type };
    } else {
      console.error("Formato de ficheiro não suportado para pré-visualização.");

    }
  }
}


function removeFile() {
  if (filePreview.value) {
    URL.revokeObjectURL(filePreview.value.url);
  }
  selectedFile.value = null;
  filePreview.value = null;
}


const canSend = computed(() => {
  return (text.value.trim().length > 0) || (selectedFile.value !== null);
});

watch(selectedFile, (newFile, oldFile) => {
  if (oldFile && filePreview.value) {
    URL.revokeObjectURL(filePreview.value.url);
    filePreview.value = null;
  }
});

onBeforeUnmount(() => {
  if (filePreview.value) {
    URL.revokeObjectURL(filePreview.value.url);
  }
});

const id = nanoid();

const openInputMediaStore = useOpenInputMediaStore();
watch(openInputMediaStore.openInput, (mapping) => {
  const data = mapping.get(id);
  if (data) {
    if (typeof data.data === 'boolean') {
      console.error("WksOpenInput Caught boolean data from the Store! Not supported inside the code");
      return;
    }

    const payload: MediaPayload = {
      text: '',
      files: []
    };

    if (typeof data.data === 'string') {
      payload.text = data.data;
    } else if (Array.isArray(data.data)) {
      payload.files = data.data;
    }

    emit("set-media", payload, data.type);
    console.log("WksOpenInput Caught data from the Store!", data);
  }
});

const openMediaModal = async () => {
  if (!filePreview.value || !isImage(filePreview.value.type)) return;

  const modal = await modalController.create({
    component: WksMediaModal,
    componentProps: {
      files: [{
        source: filePreview.value.url,
        type: filePreview.value.type,
      }],
      index: 0,
    },
    cssClass: ["wks-media-modal"],
  });
  await modal.present();
};
const textAreaRows = ref<number>(5);

function shrinkArea(e: FocusEvent) {
  setTimeout(() => {
    textAreaRows.value = 1;
  }, 100);
}

function openRoute(name: string, params: RouteParamsRaw) {
  router.push({
    name,
    params,
  });
}

const inputElement = ref<HTMLInputElement | null>(null);
async function fsSelectFile() {
  if (inputElement.value) {
    inputElement.value.click()
  }
}

</script>



<style scoped>
.ion-padding {
  padding-top: 0;
}

.relative {
  position: relative;
}

.wrapper {
  background-color: var(--input-background);
  border-radius: 0.559835185rem;
  width: 100%;
  min-height: 42px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.671802221rem;
  gap: 1%;
}

.file-preview {
  background-color: var(--input-background);
  height: auto;
  border-radius: 0.559835185rem;
  padding: 0 0.671802221rem;
}

.image-preview {
  width: 90%;
  position: relative;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-preview img {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.pdf-preview {
  width: 90%;
  position: relative;
  left: 5%;
  display: flex; 
  justify-content: center;
  align-items: flex-start;
  max-height: 215px;
  overflow-y: auto;
  background-color: var(--input-background);
  border-radius: 0.559835185rem;
  padding: 0 0.671802221rem;
  margin-bottom: 15px;
}

.pdf-preview embed {
  max-width: 100%;
  max-height: 200px;
}

.web .wrapper {
  min-width: 45%;
}

.flexbox {
  min-height: 42px;
  display: flex;
  flex-direction: row;
  gap: 0.559835185rem;
  width: 100%;
}

.mobile .flexbox {
  min-height: 42px;
  display: flex;
  flex-direction: column;
  gap: 0.559835185rem;
  width: 100%;
}


textarea,
textarea:focus {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  resize: none;
}

textarea::placeholder,
textarea {
  font-family: var(--placeholder-font);
  font-size: var(--placeholder-font-size);
  font-weight: var(--placeholder-font-weight);
  text-transform: var(--placeholder-transform);
}

textarea:disabled {
  cursor: not-allowed;
  opacity: 0.25;
}

.open-input-box {
  height: 100%;
  padding: 2% 0;
  display: flex;

  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1;
}

.web .open-input-box {
  padding: 0;
}

.input-box-wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;

  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1;
}

.input-box-wrapper>img {
  margin: auto 0;
  height: 80%;
  aspect-ratio: 1 / 1;
}

.icons {
  height: 100%;

  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  flex-grow: 0;
  flex-basis: fit-content;
  flex-shrink: 0;

  overflow: visible;
  position: relative;
}

.icons img {
  max-height: 65%;
  height: 1.5rem;
  max-width: none;
  cursor: pointer;
}

.web .icons img {
  height: 3rem;
}

.send-button {
  cursor: pointer;
}

.send-button {
  cursor: pointer;
  transform: scale(0.7);
}

.send-button img {
  height: 100%;
  aspect-ratio: 1 / 1;
}

.web .send-button img {
  transform: scale(1.6);
}

.send-enter-active,
.send-leave-active {
  transition: all 0.3s ease-in-out;
}

.send-enter-from,
.send-leave-to {
  transform: scale(0);
}

.icons-enter-active,
.icons-leave-active {
  transition: all 0.3s ease-in-out;
}

.icons-enter-from,
.icons-leave-to {
  transform: scale(0);
}

.action-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.action-button {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  transition: transform 0.2s, background-color 0.2s;
}

.remove-button {
  transform: scale(0.7);
}

.input-button {
  display: flex;
}
</style>
