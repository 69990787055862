<template>
  <Swiper
    id="swiper"
    v-if="!isWeb"
    :space-between="20"
    :grab-cursor="true"
    :autoplay="{ delay: 3000 }"
    :speed="400"
    :initial-slide="initialSlide"
    :slides-per-view="slidesPerView"
  >
    <SwiperSlide
      v-for="(file, key) in files"
      :key="key"
      @swiper="setSwiperRef"
      class="swiperSlide"
    >
      <PdfViewer
        v-if="getViewerType(file?.type ?? '') === 'pdf'"
        :key="
          key +
          wksHandler.$.task.uuid.value +
          wksHandler.$.activity.uuid.value +
          '_modal'
        "
        :source="file.source"
        id="modal"
        :zoomable="zoomable"
        :rotation="rotation"
      />
      <VideoViewer
        v-if="getViewerType(file?.type ?? '') === 'video'"
        :source="file.source"
        :zoomable="false"
      />
      <ImageViewer
        v-if="getViewerType(file?.type ?? '') === 'image'"
        :source="file.source"
        :zoomable="zoomable"
        :rotation="rotation" 
      />
      <AudioPlayer
        v-if="getViewerType(file?.type ?? '') === 'audio'"
        :source="file.source"
        :type="file.type"
        thumbnail="/assets/Soundwave.svg"
        :zoomable="false"
      />
      <span v-if="getViewerType(file?.type ?? '') === 'other'"></span>
    </SwiperSlide>
  </Swiper>

  <div class="viewer-wrapper" v-if="isWeb">
    <PdfViewer
      v-if="files?.at(0) && getViewerType(files?.at(0)?.type ?? '') === 'pdf'"
      :source="files!.at(0)!.source"
      id="modal"
      :zoomable="zoomable"
    />
    <VideoViewer
      v-if="files?.at(0) && getViewerType(files?.at(0)?.type) === 'video'"
      :source="files!.at(0)!.source"
      :zoomable="false"
    />
    <ImageViewer
      v-if="files?.at(0) && getViewerType(files?.at(0)!.type) === 'image'"
      :source="files[0].source"
      :zoomable="zoomable"
      :rotation="rotation" 
    />
    <AudioPlayer
      v-if="files?.at(0) && getViewerType(files?.at(0)?.type) === 'audio'"
      :source="files[0].source"
      :type="files[0].type"
      thumbnail="/assets/Soundwave.svg"
      :zoomable="false"
    />
    <span v-if="getViewerType(files![0].type) === 'other'"></span>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import VideoViewer from "@/modules/a.worksheets/views/components/VideoViewer.vue";
import ImageViewer from "@/modules/a.worksheets/views/components/ImageViewer.vue";
import AudioPlayer from "@/modules/a.worksheets/views/components/AudioPlayer.vue";
import { IFile } from "@/modules/a.worksheets/stores/worksheetDataStore";
import PdfViewer from "@/globals/components/PdfViewer/PdfViewer.vue";
import { useWorksheetHandler } from "@/globals/helpers/worksheetHandler";
import { computed, ref, toRefs } from "vue";
import { useDeviceStatus } from "@/stores/deviceStatus";
import {
  getViewerType,
  MimeType,
  resolveMimeType,
} from "@/globals/helpers/media/mimeTypeUtils";
import { IFileResolved, useIFileResolver } from "@/modules/a.worksheets/helpers/useIFileResolver";

type TSwiper = typeof Swiper;

defineProps({
  files: { type: Array<IFileResolved>, default: () => [] },
  slidesPerView: { type: Number, default: () => 1 },
  zoomable: { type: Boolean, default: () => true },
  initialSlide: { type: Number, default: () => 0 },
});
const emit = defineEmits(["getPopUp", "getSwiper"]);
const wksHandler = useWorksheetHandler();
const setSwiperRef = (swiper: TSwiper) => emit("getSwiper", swiper);

const { isWeb } = toRefs(useDeviceStatus());


const rotation = ref(0);
function rotate(){
  rotation.value += 90;
  if(rotation.value === 360){
    rotation.value = 0;
  }
  console.log("rotation value: ", rotation.value);
}

defineExpose({
  rotate,
});
</script>

<style scoped>
#swiper {
  height: 100%;
  width: 100%;
}
.swiperSlide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  overflow: auto;
  max-height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
.textfield {
  width: 100%;
}
</style>

<style>
#swiper .zoomer {
  display: flex;
  justify-content: center;
}
.viewer-wrapper {
  height: 100%;
  width: 100%;
}
</style>
